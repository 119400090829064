import React from "react";
import { motion } from "framer-motion";
import vessel from "../lexus-hybrid-anim-1.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AboutUs.css";
import Footer from "../footer/footer";

function AboutUs() {
  const dropFromTop = {
    hidden: { opacity: 0, y: -80 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <section className="nav-link-image mt-2">
        <div className="text-container">
          <h4>About Us</h4>
        </div>
      </section>
      <section className="service-section">
      <div className="col-md-12 d-flex justify-content-center align-items-center">
            <img
              src={vessel} // Use imported image
              alt="car"
              style={{ width: '70%' }}
            />
          </div>
        <div className="row d-flex responsive">
          <div className="col-md-12">
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
            >
              <h3>About Staple Ford</h3>
              <h2 className="mt-5">
                Experienced. Committed to Quality. Inclusive..
              </h2>
            </motion.div>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              We at Stapleford Autocentre offer an exceptional experience to car owners like you to ensure your
              convenience and satisfaction. With years of experience and expertise, we specialize in effective
              automotive repair services. We are an authorized M.O.T. testing facility catering to your needs at
              market-competitive yet affordable prices.
            </motion.p>

            <motion.h2
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-5"
            >
              Purpose-driven Auto Repair Service..
            </motion.h2>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              Is there a problem with your engine? Is it time to service your gearbox? We can quickly fix the issue
              with our comprehensive engine diagnostics services. We offer same-day car repair services to ensure
              you can get back on the road at your earliest convenience. Our expert mechanics and technicians can
              detect and repair even the most complicated issues, leveraging cutting-edge tools and state-of-the-art
              equipment to ensure your satisfaction.
            </motion.p>

            <motion.h2
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-5"
            >
              We Are Passionate About What We Do..
            </motion.h2>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              We at Stapleford Autocentre offer exceptional car repair and maintenance services while ensuring to
              diagnose any fault with perfection and quickly, enabling you to get back behind the wheel in no time.
              For this purpose, we have onboarded a team of expert and Certified Car Technicians who do the job with
              immaculate precision and perfection.
            </motion.p>

            <motion.h2
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-5"
            >
              Experienced In Fault Finding..
            </motion.h2>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              Our highly qualified and experienced mechanics have the knowledge and expertise to spot flaws in your
              car early on and fix them timely, allowing you to avoid costly repairs in the future. If you’re
              concerned about brake repair or your engine is overheating, you can visit us anytime, and we’ll
              promptly fix the issue, ensuring your convenience and satisfaction.
            </motion.p>

            <motion.h2
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-5"
            >
              Top-Notch Service @ Market-Competitive Rates..
            </motion.h2>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              We offer top-notch car repair and maintenance services at market-competitive prices.
              <ul>
                <li>Just mot 40</li>
                <li>Major service with mot including 60-point checklist 250</li>
                <li>Mini service with mot 150.</li>
              </ul>
            </motion.p>

            <motion.h2
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-5"
            >
              We Prioritize Your Satisfaction..
            </motion.h2>

            <motion.p
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              className="mt-4 line-height"
            >
              You can count on us to deliver top-quality vehicle repair service
              <ul>
                <li>Friendly & highly experienced mechanics</li>
                <li>Fault finding using the latest engine diagnostics equipment</li>
                <li>We cater to car owners, car sellers, car buyers, and companies that own fleet.</li>
                <li>Prompt & professional service.</li>
              </ul>
            </motion.p>
          </div>
          {/* <div className="col-md-5">
            <motion.img
              initial="hidden"
              whileInView="visible"
              variants={dropFromTop}
              transition={{ duration: 0.3, ease: "easeInOut" }} // Adjusted duration and ease
              src={vessel}
              alt="car"
              style={{ width: "100%" }}
            />
          </div> */}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;
